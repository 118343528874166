import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/header/Header.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/Footer.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderLanguageSwitch"] */ "/workspace/src/components/header/HeaderLanguageSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterLocaleSwitch"] */ "/workspace/src/components/i18n/FooterLocaleSwitch.tsx");
