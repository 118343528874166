'use client'

import {ReactNode, useEffect, useRef, useState} from "react";
import LocaleSelectorPanel from "@/components/i18n/LocaleSelectorPanel.tsx";

export function LocaleSelectorButton({className, openClassName, children}: {
  className?: string,
  openClassName?: string,
  children: ReactNode
}) {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleOutsideClick = (event: MouseEvent) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }
  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [buttonRef])
  return <button className={[className, open && openClassName].join(' ')}
                 onClick={() => setOpen(!open)}
                 ref={buttonRef}>
    {children}
    <LocaleSelectorPanel open={open}/>
  </button>
}
