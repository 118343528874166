'use client'

import style from "./LocaleSelectorPanel.module.sass";
import {useLocale} from "next-intl";
import {usePathname} from "@/navigation.ts";
import {localeConfigs} from "@/i18n.tsx";
import {classList} from "@/utils/lib.ts";

export default function LocaleSelectorPanel({open}: { open: boolean }) {
    const locale = useLocale()
    const pathname = usePathname()
    return <ul className={classList(style['languageDropdown'], !open && style['hidden'])} aria-hidden={open ? "false" : "true"}>
        {localeConfigs.map(l => <li key={l.code}>
            <a href={l.prefix + pathname} className={l.code === locale ? style['active'] : ''} tabIndex={open ? undefined : -1}>
                {l.name}
            </a>
        </li>)}
    </ul>
}
